<template>
  <div class="menu-edit-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-button class="btn ml-3" type="button" @click="returnPage">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-3"
          type="button"
          :disabled="loading"
          @click="submit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </template>
    </basic-subheader>
    <b-container fluid class="menu-edit-page__body mb-6 mt-6">
      <b-overlay :show="loading">
        <FormCoach ref="formCoach" @submit="createCoachGroup" />
      </b-overlay>
    </b-container>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import {
  SET_SELECTED_PATIENTS,
  GET_PREPARE,
  RESET_STATE,
} from '@/core/services/store/coach.module';
const { mapMutations, mapActions } = createNamespacedHelpers('coach');
export default {
  name: 'CoachCreate',
  components: {
    FormCoach: () => import('./components/FormCoach.vue'),
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.setupData();
  },
  beforeDestroy() {
    this.RESET_STATE();
  },
  methods: {
    ...mapMutations({ SET_SELECTED_PATIENTS, RESET_STATE }),
    ...mapActions({ GET_PREPARE }),
    async setupData() {
      this.loading = true;
      await this.GET_PREPARE();
      this.loading = false;
    },
    returnPage() {
      this.$router.go(-1);
    },
    async createCoachGroup(params) {
      this.loading = true;
      try {
        if (params.file) {
          const fileId = await this.uploadImage(params.file);
          if (!fileId) return;
          delete params.file;
          params['coverId'] = fileId;
          console.log(fileId);
        }
        const { meta, error, data } = await this.$api.post(
          '/TrainingGroup',
          params,
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Tạo nhóm mới thành công',
        });
        this.$router.push({
          name: 'coach_detail',
          params: {
            id: data.id,
          },
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    submit() {
      this.$refs.formCoach.handleSubmit();
    },
    async uploadImage(file) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }

      return data.id;
    },
  },
};
</script>
<style lang=""></style>
